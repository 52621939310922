@import '../node_modules/foundation-sites/scss/foundation';
// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grid
@include foundation-xy-grid-classes;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;

@include foundation-reveal;

@import "../node_modules/foundation-sites/scss/util/util";
@import "../node_modules/foundation-sites/scss/util/breakpoint";

// Foundation Icons
@import "assets/foundation-icons/foundation-icons.css";

html {
  min-height: 100vh;
}

body {
  min-height: 100vh;
  color: white;
}

button:focus {
  outline: none;
}
